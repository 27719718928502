.incoming-calls-body {
    padding: 20px;
}

.incoming-calls-filters {
    align-items: center;
    background: lightblue;
    display: flex;
    padding: 10px;
}

.filter-label {
    font-size: large;
    margin: 0px 10px 0px 10px;
}

.incoming-calls-table-header {
    align-items: center;
    display: flex;
    padding: 5px;
}

.incoming-calls-table-title {
    font-size: x-large;
    font-weight: 600;
    margin-left: 10px;
}

.calls-table-action {
    margin-right: 10px;
    background: lightblue;
    color: darkblue;
    padding: 5px;
    border-width: 0px;
}
