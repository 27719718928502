a {
    /* color: rgb(98, 211, 248); */
}

a:hover {
    /* color: white; */
}

body {
      color: rgb(25, 25, 80);
}
