.tucuxi-app-header {
  background: #eee;
  display: flex;
  height: 100px;
  padding: 10px;
  padding-bottom: 0px;
}

#tucuxi-app-header-logo {
  height: 80px;
}

#cody-wrapper {
  display: none;
}

.tucuxi-app-header-bar {
  align-self: flex-end;
  /* background: rgb(251, 250, 255); */
  display: flex;
  height: 43px;
  justify-content: space-between;
  margin: 6px;
  padding: 5px;
  width: 100%;
}

.tucuxi-app-login-button {
  float: right;
  width: 80px;
}

.header-center-content {
  font-size: 30px;
  font-weight: 600;
}

.header-right-content {
  /* color: rgb(88, 201, 238); */
  display: flex;
  align-items: flex-end;
}

.login-button-container {
  display: grid;
  justify-items: end;
  padding-left: 77px;
  padding-right: 10px;
}

.header-logged-in-user {
  float: right;
  margin-top: 5px;
}

.tucuxi-page-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.tucuxi-page-content-primary {
  padding: 20px;
  text-align: center;
  max-width: 1300px;
  width: 90%;
}

.tucuxi-page-content-secondary {
  /* color: rgb(25, 25, 80); */
  padding: 20px;
  text-align: center;
  max-width: 1300px;
}

.tucuxi-page-lead-capture {
  /* color: rgb(98, 211, 248); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-form-submit-button {
  background-color: rgb(74, 74, 255);
  width: 100%;
}

.form-submit-acknowledgement {
  background-color: #f0f0ff;
  box-shadow: 5px 5px #aaa;
  text-align: center;
}

.tucuxi-app-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
}

.tucuxi-form-terms-agreement {
  font-size: small;
  font-weight: 400;
  margin: 5px;
}

.tucuxi-terms-privacy-copyright {
  display: flex;
  width: 270px;
  justify-content: space-between;
  margin: 20px;
}

.tucuxi-home-header {
  /* background-color: rgb(0, 93, 191); */
  /* color: rgb(98, 211, 248); */
  display: flex;
  flex-direction: column;
  font-size: 24pt;
  font-style: italic;
  font-weight: 600;
  text-align: center;
}

.tucuxi-home-guest-sign-up-text {
  font-size: 14pt;
  margin: 10px;
}

.tucuxi-home-product-section {
  background: rgb(98, 211, 248);
  border-color: rgb(98, 211, 248);
  box-shadow: 5px 5px 3px lightgray;
  border-radius: 20px;
  color: rgb(0, 93, 191);
  display: flex;
  flex-direction: column;
  margin: 50px;
  padding: 20px;
}

.tucuxi-home-product-header {
  /* color: white; */
  color: rgb(0, 93, 191);
  font-size: 28pt;
  font-weight: 600;
  margin: 50px;
}

.tucuxi-home-product-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
}

.tucuxi-home-product-name {
  color: rgb(25, 25, 80);
  font-size: 32pt;
  font-weight: 400;
  margin: 20px;
  width: 20%;
}

.tucuxi-home-product-name > a {
  color: rgb(25, 25, 80);
}

.tucuxi-home-product-description {
  font-size: 18pt;
  font-weight: 400;
  margin: 0 20px 20px 20px;
  width: 70%;
}

.learn-more-link {
  margin-top: 20px;
}

.learn-more-link a {
  color: white;
}