.incoming-calls-body {
    padding: 20px;
}

.incoming-calls-table-title {
    font-size: large;
    font-weight: 600;
}

.calls-table-action {
    margin-right: 10px;
    background: lightblue;
    color: darkblue;
    padding: 5px;
    border-width: 0px;
}
