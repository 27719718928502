.LoginDialog-root {
    max-width: 400px;
    min-width: 360px;
}

.LoginDialog-buttons {
    display: -webkit-inline-box;
}

.LoginDialog-error {
    color: red;
    font-style: italic;
    height: 20px;
    margin-left: 10px;
}

.LoginDialog-input {
    height: 25px;
    margin: 10px;
}

.LoginDialog-prompt {
    color: #575756;
    height: 25px;
    margin: 10px;
}

.LoginDialog-terms-agreement {
    font-size: small;
    font-weight: 400;
    margin: 10px;
}